import React from "react"
import {
    Row,
    Col,
    Modal,
    ModalBody,
    Button,
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import { connect } from 'react-redux'
import { toast } from "react-toastify"
import DeltaPostLogo from "./../assets/images/DeltaPostLogo.png"
import axios from 'axios'
import { QRCode } from 'react-qrcode-logo'
import Keyboard from "../components/Keyboard"
import scssVars from "./../assets/sass/vars.scss"
import { changeLanguage } from './../redux/actions/i18n'
import { withTranslation } from 'react-i18next'



class Delivery extends React.Component {
    state = {
        maxPinLength: 6,
        qrCode: process.env.REACT_APP_QRCODE_VALUE_CLIENT,
        pin: '',
        savedPin: '',
        inputRegex: new RegExp('^[0-9]+$'),
        keys: [],
        animation: '',
        showQrcode: false,
        showKeyboard: true,
        isSubmitButtonDisabled: true,
        currentDoor: '',
        lang: this.props.lang,
        showOpenDoorModal: false,
        counter: 0,
        initialTime: process.env.REACT_APP_QRCODE_TIMER * 10,
        startTimer: false,
        timeLeft: 0,
        locationIdError: false
    }

    componentDidMount() {
        this.generateKeys()
        this.props.i18n.changeLanguage(this.props.lang)
    }

    changeLanguage = (e, lang) => {
        e.stopPropagation()
        this.props.dispatch(changeLanguage(lang)).then(() => {
            this.props.i18n.changeLanguage(lang)
            toast.success(this.props.t("toast.change-lang"), { toastId: lang, position: toast.POSITION.TOP_CENTER },)
        })
    }


    resetActivityTimer = () => {
        var self = this
        clearTimeout(this.activityTimer)
        this.setState({ showOpenDoorModal: false })
        this.activityTimer = setTimeout(
            function () {
                self.setState({
                    pin: '',
                    isSubmitButtonDisabled: true
                })
                //reset language to pt
                if (!self.state.showOpenDoorModal) {
                    self.props.dispatch(changeLanguage('pt')).then(() => {
                        self.props.i18n.changeLanguage('pt')
                    })
                }
            }, 20000)
    }

    cleanPin = () => {
        this.setState({
            pin: '',
            isSubmitButtonDisabled: true,
        })
    }

    generateKeys = () => {
        var keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        //shuffles keys into a random order
        // for (let i = keys.length - 1; i > 0; i--) {
        //     const j = Math.floor(Math.random() * i)
        //     const temp = keys[i]
        //     keys[i] = keys[j]
        //     keys[j] = temp
        // }

        keys.push('⇦')
        keys.splice(keys.length - 2, 0, "#")
        this.setState({ keys: keys })
    }

    handleClick = (val) => {
        this.resetActivityTimer()
        if (val === '#') {
            this.cleanPin()
        } else if (val === '⇦') {
            const newPin = this.state.pin.slice(0, -1)
            this.setState({ pin: newPin }, () => {
                this.checkPinLength()
            })
        } else {
            if (this.state.pin.length < 6) {
                const newPin = this.state.pin.concat(val)
                this.setState({ pin: newPin }, () => {
                    this.checkPinLength()
                })
            }
        }
    }

    checkPinLength = () => {
        if (this.state.pin.length < 6) {
            this.setState({ isSubmitButtonDisabled: true })
        } else {
            this.setState({ isSubmitButtonDisabled: false })
        }
    }

    setAnimation = name => {
        this.setState({ animation: name })
        setTimeout(function () {
            this.setState({ animation: '' })
        }.bind(this), 1000)
    }

    modalCloseTimer = () => {
        setTimeout(function () {
            this.setState({ showOpenDoorModal: false })
        }.bind(this), 10000)
    }

    updateCounter = () => {
        if (this.state.counter < 7) {
            this.setState({ counter: this.state.counter + 1 })
        }
    }



    verifyPin = () => {
        this.resetActivityTimer()
        if (!this.state.isSubmitButtonDisabled) {
            this.setState({ isSubmitButtonDisabled: true })
            var self = this
            let pin = this.state.pin
            this.setState({ pin: '', savedPin: pin })
            axios.get(`${process.env.REACT_APP_API_URL}api/partner/orders/verifyPickUpOrderWithPin?pin=${pin}&locationId=${process.env.REACT_APP_LOCATION_ID}`, {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
                }
            })
                .then(res => {
                    self.setState({ isSubmitButtonDisabled: false })
                    if (res.data.ResultCode === 1) {
                        this.setState({ currentDoor: res.data.Data.assignedLocker.doorNumber })
                        self.toggleOpenDoorModal(true)
                        self.modalCloseTimer()
                        self.setAnimation('success')
                    } else {
                        if (res.data?.Error === "L6") {
                            toast.error(this.props.t("toast.error-location"), { toastId: 'error-location', position: toast.POSITION.TOP_CENTER },)
                            self.setAnimation('error')
                        }
                        else {
                            toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                            self.setAnimation('error')
                        }

                    }
                })
                .catch((err) => {
                    self.setState({ isSubmitButtonDisabled: false })
                    self.setAnimation('error')
                    toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                })
        }
    }

    onSubmit = () => {
        this.resetActivityTimer()
        if (!this.state.isSubmitButtonDisabled) {
            this.setState({ isSubmitButtonDisabled: true })
            var self = this
            let pin = this.state.savedPin
            this.setState({ savedPin: '' })
            const formData = new FormData();
            formData.append('pin', pin);
            formData.append('locationId', process.env.REACT_APP_LOCATION_ID);
            axios.post(`${process.env.REACT_APP_API_URL}api/partner/orders/pickUpOrderWithPin`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
                }
            })
                .then(res => {
                    self.setState({ isSubmitButtonDisabled: false })
                    if (res.data.ResultCode === 1) {
                        this.setState({ currentDoor: res.data.Data.assignedLocker.doorNumber })
                        self.setAnimation('success')
                        //reset language to pt
                        self.props.dispatch(changeLanguage('pt')).then(() => {
                            self.props.i18n.changeLanguage('pt')
                        })
                    } else {
                        if (res.data?.Error === "L6") {
                            toast.error(this.props.t("toast.error-location"), { toastId: 'error-location', position: toast.POSITION.TOP_CENTER },)
                            self.setAnimation('error')
                            //reset language to pt
                            self.props.dispatch(changeLanguage('pt')).then(() => {
                                self.props.i18n.changeLanguage('pt')
                            })
                        }
                        else {
                            toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                            self.setAnimation('error')
                            //reset language to pt
                            self.props.dispatch(changeLanguage('pt')).then(() => {
                                self.props.i18n.changeLanguage('pt')
                            })
                        }
                    }
                })
                .catch((err) => {
                    self.setState({ isSubmitButtonDisabled: false })
                    self.setAnimation('error')
                    toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                    //reset language to pt
                    self.props.dispatch(changeLanguage('pt')).then(() => {
                        self.props.i18n.changeLanguage('pt')
                    })
                })
        }
    }

    toggleOpenDoorModal = (val) => {
        this.resetActivityTimer()
        this.setState({ showOpenDoorModal: val })
    }

    cancel = () => {
        this.setState({ showOpenDoorModal: false })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter !== prevState.counter) {
            clearTimeout(this.timer);
    
            if (this.state.counter < 7) {
                this.timer = setTimeout(() => this.setState({ counter: 0 }), 2 * 1000);
            }
        }
    
        if (this.state.counter === 7) {
            if (!this.state.startTimer) {
                this.setState({
                    startTimer: true,
                    timeLeft: 0
                });
    
                this.timerId = setInterval(() => {
                    if (this.state.timeLeft < this.state.initialTime) {
                        this.setState({ timeLeft: this.state.timeLeft + 1 });
                    } else {
                        clearInterval(this.timerId);
                        this.setState({
                            startTimer: false,
                            counter: 0,
                            timeLeft: 0
                        });
                    }
                }, 100);
            }
        }
    }

    render() {
        
        const { t } = this.props

        const { showOpenDoorModal, isSubmitButtonDisabled } = this.state

        const pinInputs = []

        for (var i = 0; i < this.state.maxPinLength; i++) {
            pinInputs.push(<Col className="col-2 pin-key" key={i}>
                {this.state.pin[i] && <div><div className="pin-div">{this.state.pin[i]}</div><div className="float"></div></div>}
            </Col>)
        }

        const openDoorModal = (
            <>
                <Modal
                    backdrop="static"
                    isOpen={showOpenDoorModal}
                    toggle={() => this.toggleOpenDoorModal(!this.state.showOpenDoorModal)}
                    centered
                    size="lg"
                >
                    <ModalBody className="p-0 modal-body modal-size d-flex justify-content-center align-items-center py-5">
                        <div className="m-0 p-0 py-5">
                            <div className=" m-0 mx-auto p-1" style={{ width: 180, height: 180, borderRadius: "1rem", border: "2px solid rgba(228, 26, 21, 0.7)" }}>
                                <span className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ fontSize: "50px", borderRadius: "1rem", border: `6px solid ${scssVars?.red}` }}>{this.state.currentDoor}</span>
                            </div>
                            <p className="modal-text text-center m-0 p-0 mt-5 mb-1" style={{ fontSize: "25px" }}>{t('delivery.doorHasBeenOpen')}{this.state.currentDoor}.</p>
                            <p className="modal-text text-center m-0 p-0 mt-1 mb-5" style={{ fontSize: "25px" }}>{t("delivery.pleaseRetrieve")}</p>
                            <Row className="m-0 justify-content-center p-0">
                                <Button
                                    style={{ background: scssVars?.red, border: 0, fontSize: "1.8vw", borderRadius: scssVars?.borderRadius }}
                                    disabled={this.state.isSubmitButtonDisabled}
                                    className="modal-btn py-3 w-75"
                                    onClick={this.onSubmit}>
                                    {t("button.openDoor")}
                                </Button>
                                {/* <Button
                          color="danger"
                          className="modal-btn m-3"
                          onClick={() => this.cancel()}>
                          {t("button.cancel")}
                      </Button> */}
                            </Row>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )

        return (
            <>
                {openDoorModal}
                <div className="office-container container-fluid">
                    <Row className="lang-row">
                        <button className={"pt " + (this.props.lang === 'pt' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'pt')}>PT</button>
                        <button className={"en " + (this.props.lang === 'en' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'en')}>EN</button>
                        <button className={"es " + (this.props.lang === 'es' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'es')}>ES</button>
                    </Row>
                    <Row className="pin-submit-office">
                        <Col className="col-5 keyboard-col">
                            <Row className="logo-row">
                                <a href={process.env.REACT_APP_URL_HOME}>
                                    <img src={DeltaPostLogo} className="logo-img" />
                                </a>
                            </Row>
                            <div className="keyboard-wrapper">
                                <Row className="justify-content-start align-items-center">
                                    <Row
                                        className={`input-wrapper ${this.state.animation}`}
                                    >
                                        {pinInputs}
                                    </Row>
                                </Row>
                                <Row className="justify-content-start keys-row">
                                    <Col>
                                        <Keyboard
                                            handleClick={this.handleClick}
                                            keys={this.state.keys}
                                            isSubmitting={this.state.isSubmitting}
                                            animation={this.state.animation} />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center confirm-button-row">
                                    <Button
                                        disabled={isSubmitButtonDisabled}
                                        className="confirm-btn"
                                        onClick={() => this.verifyPin()}>
                                        {t("button.btn-confirm")}
                                    </Button>
                                </Row>
                            </div>
                        </Col>
                        <Col className="col-7 help-section">
                            <div className="container-fluid help-container">
                                <Row className="section-title">
                                    <h2>{t(`delivery.${this.state.counter === 7 ? "title2" : "title"}`)}</h2>
                                </Row>
                                <Row className="qr-div justify-content-center" onClick={() => this.updateCounter()}>
                                    <QRCode
                                        value={this.state.counter === 7 ? process.env.REACT_APP_QRCODE_VALUE_STAFF : process.env.REACT_APP_QRCODE_VALUE_CLIENT}
                                        fgColor={scssVars.qrcolor}
                                        size="200" />
                                </Row>
                                <div className="mt-4 mx-auto overflow-hidden" style={{ height: 10, width: 210, borderRadius: scssVars.borderRadius }}>
                                    {
                                        this.state.counter === 7 &&
                                        <div className="h-100 w-100 m-0 p-0 position-relative" style={{ borderRadius: scssVars.borderRadius, background: "#E3E3E3" }}>
                                            <div className="timerQrCode m-0 p-0 h-100 w-100 position-absolute"
                                                style={{
                                                    transition: "0.1s all",
                                                    borderRadius: scssVars.borderRadius,
                                                    background: scssVars.red,
                                                    left: `-${(this.state.timeLeft / this.state.initialTime) * 100}%`,
                                                }}
                                            >
                                            </div>
                                        </div>
                                    }
                                </div>
                                <Row className="subtitle-row">
                                    <p>
                                        {t("delivery.sub-title.text-1")}
                                        <br /><span>{t("delivery.sub-title.or")}</span><br />
                                        {t("delivery.sub-title.text-2")}
                                    </p>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        lang: state.i18n.language
    }
}

export default connect(mapStateToProps)(withTranslation('common')(Delivery))
